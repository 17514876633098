import image from "../../assets/images/profile-picture.jpg";

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<any>>;
};

const HomeComponent: React.FC<Props> = (props) => {
  return (
    <div className="container relative py-8">
      <div className="relative flex flex-col justify-center items-center py-2">
        <div className="px-6 pb-8 w-full rounded-xl shadow-lg border bg-gray-700 border-primary-500 animate__animated animate__fadeIn">
          <h1 className="animate__animated animate__fadeIn text-center mb-2 text-2xl lg:text-4xl font-bold tracking-tight text-primary-500">
            Welcome to Ethan Farrell's Portfolio Website!
          </h1>
        </div>
      </div>
      <div className="relative flex flex-col justify-center items-center py-2 animate__animated animate__fadeIn">
        <div className="px-6 pb-8 w-full lg:w-full  rounded-xl shadow-lg border bg-gray-700 border-primary-500">
          <h2 className="text-center m-2 text-3xl lg:text-4xl text-primary-500">
            About Me
          </h2>
          <div className="text-gray-200 text-xl ">
          My name is Ethan Farrell and you have successfully landed on my
            website. Everything you need to know about me is on this website,
            including methods of getting in touch with me. I am a current student at 
            NSCC in Truro, Nova Scotia. I am taking the IT Systems Management & Security program offered at the college. 
            I also have a diploma in IT Web Programming from NSCC but I have decided to change careers into the Systems side of things.
          </div>
          <div className="relative blur-everything flex justify-center py-8 animate__animated z-20">
            <div className="px-4 py-4 rounded-xl shadow-lg border bg-gray-600 border-primary-500 transition duration-700">
              <img
                className="h-auto w-auto md:h-96"
                alt="Headshot of Ethan Farrell"
                src={image}
                onLoad={() => {
                  props.setLoading(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
