import React, {useEffect, Suspense} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import "./App.css";
import 'animate.css';
import Header from './components/Header/Header';
import Home from "./components/Pages/Home";
import LoadingOverlay from './components/LoadingOverylay/LoadingOverlay';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Skills = React.lazy(() => import('./components/Pages/Skills'));
const FeaturedProject = React.lazy(() => import('./components/Pages/FeaturedProject'));
const Contact = React.lazy(() => import('./components/Pages/Contact'));
const Error = React.lazy(() => import('./components/Pages/Error'));

function App() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <Header />
      <Suspense fallback={<LoadingOverlay enabled={true} bgColor={"#494949"} spinnerColor={"#4fa94d"}/>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/featured-project" element={<FeaturedProject />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Routes>
      </Suspense>
    </>
  );
}

export default App;
